import * as React from "react"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import { Link } from "gatsby"

const Support = () => {
    const pageTitle = "Support"
    const subItems = [{ name: "ISO-8217", to: "iso-8217/", collection: null }]
    return (
        <Layout>
            <Seo title={pageTitle} />
            <div className="container py-3">
                <h1>{pageTitle}</h1>
                <ul>
                    {subItems.map((item, idx) => {
                        return (
                            <li key={idx}>
                                <Link to={item.to}>{item.name}</Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </Layout>
    )
}

export default Support
